<template>
  <div id="pageCadastroSacola">
    <v-container grid-list-x2 fluid>
      <v-layout row wrap justify-center>
        <v-flex lg10 sm12 xs12>
          <v-card>
            <v-toolbar color="pink" dark flat dense cad>
              <v-toolbar-title v-if="this.id" class="subheading">Editar sacola {{ this.id }}</v-toolbar-title>
              <v-toolbar-title v-else class="subheading">Nova sacola</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="">
              <sacola-form></sacola-form>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import SacolaForm from "@/components/sacola/formCadastroSacola"
export default {
  components: {
    SacolaForm
  },
  data: () => ({
    id: null
  }),
  created() {
    if (this.$route && this.$route.params) {
      this.id = this.$route.params.id
    }
  }
}
</script>
