<template>
  <v-form ref="form">
    <v-layout row wrap justify-center>
      <v-flex lg12 sm12 xs12>
        <v-text-field
          class="mr-2"
          label="Adicionar produto"
          name="addProduto"
          placeholder="Código de barras"
          v-model="codigoBarra"
          ref="codigoBarra"
          append-outer-icon="library_add"
          @blur="adicionarProduto"
        ></v-text-field>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-data-table :headers="headers" :items="produtos" class="elevation-0">
            <template v-slot:items="props">
              <td style="display:none;">{{ props.item.idCodigoBarras }}</td>
              <td style="display:none;">{{ props.item.idProduto }}</td>
              <td align="right">{{ props.item.linha }}</td>
              <td>{{ props.item.codigoBarras }}</td>
              <td>{{ props.item.referencia }}</td>
              <td>{{ props.item.nomeProduto }}</td>
              <td>{{ props.item.idEntrada }}</td>
            </template>
          </v-data-table>
        </div>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog" max-width="800">
      <v-card>
        <v-card-title class="headline">Código de barras bloqueado</v-card-title>
        <v-card-text
          ><b>{{ text }}</b></v-card-text
        >
        <v-card-text>Deseja liberar o código para utilização?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            text
            @click="
              dialog = false
              codigoBarra = ''
            "
            >Cancelar</v-btn
          >
          <v-btn color="primary" dark text @click="liberarCodigo()">Liberar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="form-btn">
      <v-btn @click="voltar" outline>Voltar</v-btn>
      <v-btn outline @click="submit" color="primary">Salvar</v-btn>
    </div>
    <div>
      <progress-circular></progress-circular>
    </div>
    <v-snackbar :color="'error'" v-model="snackbar" :top="true">
      {{ text }}
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </v-form>
</template>
<script>
import { serverBus } from "../../main"
import ProgressCircular from "../progressCircular/progressCircular"
import ProdutoSacola from "../../domain/sacola/ProdutoSacola"
import ServicoSacola from "../../servicos/servicoSacola"
const servicoSacola = new ServicoSacola()
export default {
  components: {
    ProgressCircular
  },
  data() {
    return {
      dialog: false,
      codigoBarra: "",
      codigoLiberar: "",
      produtos: [],
      quantidade: 0,
      snackbar: false,
      text: "",
      headers: [
        { text: "Quantidade", align: "left", sortable: false },
        { text: "Código de Barras", align: "left", sortable: false, value: "codigoBarras" },
        { text: "Referência", align: "left", sortable: false, value: "referencia" },
        { text: "Descrição", align: "left", sortable: false, value: "nomeProduto" },
        { text: "Nota Entrada", align: "left", sortable: false, value: "idEntrada" }
      ]
    }
  },
  created() {},
  methods: {
    focusInput: function(inputRef) {
      // $refs is an object that holds the DOM references to your inputs
      this.$refs[inputRef].focus()
    },
    adicionarProduto() {
      if (this.codigoBarra !== "") {
        var enviar = this.codigoBarra
        this.codigoBarra = ""

        this.focusInput("codigoBarra")

        if (this.produtos.length > 0) {
          var codigoEncontrado = this.produtos.find(element => element.codigoBarras == enviar)
          if (codigoEncontrado) {
            this.snackbar = true
            this.text = "O código de barras: " + enviar + " já se encontra nesta sacola."
            return false
          }
        }

        servicoSacola.buscaCodigoBarras(enviar).then(res => {
          if (res.status === 200) {
            if (res.data == null) {
              this.codigoBarra = ""
              this.snackbar = true
              this.text = "O código de barras: " + enviar + " não existe no sistema."
            } else if (res.data.length > 0 && res.data[0].status == 412) {
              this.codigoLiberar = enviar
              this.dialog = true
              this.text = res.data[0].detail
            } else {
              this.codigoBarra = ""
              this.quantidade = this.quantidade + 1
              var produto = new ProdutoSacola()
              produto.linha = this.quantidade
              produto.idCodigoBarras = res.data.idCodigoBarras
              produto.idProduto = res.data.idProduto
              produto.codigoBarras = res.data.codigoBarras
              produto.referencia = res.data.referencia
              produto.nomeProduto = res.data.nomeProduto
              produto.idEntrada = res.data.idEntrada
              //this.produtos.push(produto)
              this.produtos.splice(0, 0, produto)
            }
          }
        })
      }
    },
    submit() {
      if (this.produtos.length > 0) {
        this.abrirProgressCircular()
        servicoSacola.salvar(this.produtos).then(
          res => {
            if (res.status === 200 && res.data.length === undefined) {
              this.fecharProgressCircular()
              this.$router.push({ name: "Sacola" })
            } else {
              this.fecharProgressCircular()
              this.snackbar = true
              this.text = res.data[0].title + " - " + res.data[0].detail
            }
          },
          err => {
            this.fecharProgressCircular()
            // eslint-disable-next-line
            console.log(err)
          }
        )
      }
    },
    liberarCodigo() {
      servicoSacola.liberarCodigoBarras(this.codigoLiberar).then(res => {
        if (res.status === 200) {
          this.codigoLiberar = ""
          if (res.data == null) {
            this.snackbar = true
            this.text = "O código de barras não existe no sistema."
          } else if (res.data.length > 0 && res.data[0].status == 412) {
            this.snackbar = true
            this.text = res.data[0].detail
          } else {
            this.dialog = false
            this.quantidade = this.quantidade + 1
            var produto = new ProdutoSacola()
            produto.linha = this.quantidade
            produto.idCodigoBarras = res.data.idCodigoBarras
            produto.idProduto = res.data.idProduto
            produto.codigoBarras = res.data.codigoBarras
            produto.referencia = res.data.referencia
            produto.nomeProduto = res.data.nomeProduto
            produto.idEntrada = res.data.idEntrada
            //this.produtos.push(produto)
            this.produtos.splice(0, 0, produto)
          }
        }
      })
    },
    abrirProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", true)
    },
    fecharProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", false)
    },
    voltar() {
      this.$router.push({ name: "Sacola" })
    }
  }
}
</script>
