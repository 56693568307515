export default class ProdutoSacola {
  constructor(
    linha = null,
    idCodigoBarras = "",
    idProduto = null,
    codigoBarras = "",
    referencia = "",
    nomeProduto = "",
    idEntrada = null
  ) {
    this.linha = linha
    this.idCodigoBarras = idCodigoBarras
    this.idProduto = idProduto
    this.codigoBarras = codigoBarras
    this.referencia = referencia
    this.nomeProduto = nomeProduto
    this.idEntrada = idEntrada
  }
}
